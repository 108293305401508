import React, {useState, useEffect} from 'react'
import Footer from '../../Nav/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import HTMLReactParser from 'html-react-parser';



function AssessmentSummary() {
    const [loadingstatus, setLoadingStatus] = useState(false);
    const [vigilate, setVigilate] = useState(null);
    const [years, setYears] = useState();
    const [grade, setGrade] = useState(null);
    const [gradeStatus, setGradeStatus] = useState();
    const [subjectStatus, setSubjectStatus] = useState();
    const [subject, setSubject] = useState(null);
    const [gradeID, setGradeID] = useState();
    const [mYear, setMYear] = useState(new Date().getFullYear());
    const [assStatus, setAssStatus] = useState(null);
    const [assessment, setAssessment] = useState(null);
    const [subjectID, setSubjectID] = useState();
    const [dataCapture, setDataCapture] = useState(null)
    const [student, setStudent] = useState();
    const [studentID, setStudentID] = useState();
    const [subjectName,setSubjectName] = useState();
    const [status, setStatus] = useState(null);
    const [marks,setMarks] = useState('');
    const [termo, setTermO] = useState();
    const [viewAssData, setViewAssesData] = useState(null);
    const [payStatus, setPayStatus] = useState(null);
    const [gradeData, setGradeData] = useState(null);
    const [currTerms, setcurrTerms] = useState(null);
    const task = "summary";


    const terms = [
      {termly:'Term 1', id:1},
      {termly:'Term 2', id:2},
      {termly:'Term 3', id:3},
      {termly:'Term 4', id:4}
      
    ]

    const checkTerms = () => {
      let options = "";
    
      switch (currTerms) {
        case "1":
          options = <option value={'Term 1'}>Term 1</option>;
          break;
        case "2":
          options = <option value={'Term 2'}>Term 2</option>; 
          break;
        case '3':
          options = <option value={'Term 3'}>Term 3</option>;
          break;
        case '4':
          options = <option value={'Term 4'}>Term 4</option>;
          break;
        default:
          options = <option disabled>Terms</option>;
      }
    
      return options;
    };

    const getStudentGrade = (uid) => {    
               
      axios.get('/get-user-grade',{
         headers:{ 'Content-Type': 'application/json'}
       })
       .then((response)=>{
             if(response.data.status){
              setGradeData(response.data.status)
             }else{
               setGradeData(null)
             }
       }) 
 }


   const addingMark = (mark,uploadID,totalMark) =>{
    setMarks(mark);
   
if(parseInt(mark) > parseInt(totalMark)){
  setStatus(<strong style={{color:'red'}}>Invalid Mark</strong>);
}else{
     setStatus(null)
axios.post('/add-assessmentmark',{
    mark:mark,
    uploadID:uploadID
},{
headers:{ 'Content-Type': 'application/json'}
})
}
}


    const getSubject = (gradeam) =>{
          let mDatas = gradeam.split('=>');
           if(mDatas[1] === 'Paid'){
             setPayStatus(null)
      setLoadingStatus(true);
      setStudentID(mDatas[0])
       
     axios.post('/getsubject-data',{
        grade:mDatas[0]
     }).then((response)=>{
         setTimeout(()=>{
              setLoadingStatus(false);
          if(response.data.subjectData === false){
            setSubjectStatus(<option disabled>Not Available</option>);
     }else
     {
            setSubject(response.data.subjectData);
     }
         },2000)
         
     }).catch((err)=>{
        console.log(err)
     })
    }else{
       setPayStatus(<div className='alert alert-info text-center'>You have an uncleared invoice for this learner. Kindly <Link to="/finance/unpaid-invoice" className="btn btn-primary waves-effect waves-light btn-sm">Click here  </Link></div>)
    }
}
  
  const SubjectData = (subID) => {
            setSubjectID(subID);
          setLoadingStatus(true)
         axios.post('/getassessment-data',{
              subID:subID,
              gradeID:gradeID,
              year:mYear,
              term:termo,
              cate:task
         },
         {
          headers:{ 'Content-Type': 'application/json'} 
         }).then((response)=>{
              setTimeout(()=>{
                  setLoadingStatus(false)
                if(response.data.assData === false){
                  setAssessment(null)
                      setAssStatus(<option disabled>Not Available</option>);
                      
                 }else
                 {
                  setAssStatus(null)
                       setAssessment(response.data.assData);
                       
                 }
              },2000)
               
         })   
  }

   const viewCapture = (invigilateID)=> {
//'https://termfinals-config.equationeducators.com/control_data.php';
          setLoadingStatus(true);
           let invid = invigilateID.split('-');
           
      
    let mData = {studentID:studentID,indown:invid[0],assment:invid[1],subject_id:subjectID,term:termo,grade:gradeID,year:mYear}
    let base_url = 'https://termfinals-config.equationeducators.com/control_data.php';
   // let base_url = 'http://localhost:8888/terms/control_data.php';
     $.ajax({
       url:base_url,
       data:mData,
        type:'POST',
        success:function(res){
             setLoadingStatus(false)
             setViewAssesData(res)
             
        }
     })

             
        /*   axios.post('/getinvigilate-data',{
                invigilateID:invigilateID,
                subjectID:subjectID,
                grade:gradeID,
                years:mYear,
                studentID:studentID,
                term:termo,
                cate:task

           },{
            headers:{ 'Content-Type': 'application/json'} 
           }).then((response)=>{
                 if(response.data.captureData === false){
                        setVigilate('No data found');
                 }else
                 {
                      setVigilate(null)
                      setDataCapture(response.data.captureData)

                 }
           })
  */
  
          }

     const getStudent = (gradeID)=>{
            setLoadingStatus(true);
            setGradeID(gradeID);
            axios.get('/getlearners-data/'+gradeID,{
              headers:{ 'Content-Type': 'application/json'} 
            }).then((res)=>{
                setTimeout(()=>{
                   setLoadingStatus(false)
                  setStudent(res.data.studentData);
                },2000)
                 
            })
     }

     const getSubjectID = (subID) =>{
      axios.get('/getsubject-data/'+subID,{
        headers:{ 'Content-Type': 'application/json'}
      }).then((res)=>{
            setSubjectName(res.data.msubjectData[0].subject);
            return res.data.msubjectData[0].subject;
      })
}

    useEffect(()=>{

      let min = 2020,
      max = 2040,
     select = document.getElementById('year');
 
 for (var i = min; i<=max; i++){
     var opt = document.createElement('option');
     opt.value = i;
     opt.innerHTML = i;
     select.appendChild(opt);
 }
 
 select.value = new Date().getFullYear();

      axios.get('/getassessment-years',{
       headers:{ 'Content-Type': 'application/json'} 
      }).then((response) =>{
           setYears(response.data.years);
      })

      axios.get('/mychild',{
        headers:{ 'Content-Type': 'application/json'}
      }).then((response)=>{
        setcurrTerms(response.data.term)
            if(response.data.nonUser === false){
                   setGradeStatus(<option disabled>Not available</option>);
            }else
            {
                    setGrade(response.data.result);
                    getStudentGrade(response.data.parentID)
                    
            }
      })
      
},[])
    return (
       
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Assessment Summary</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academic</li>
                        <li className="breadcrumb-item active">Assessment Summary</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
            
              <form className="row gy-3 gx-5 align-items-center" id='resetUserForm'>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                   
                  <select className="form-select form-control" id="year" onChange={(e)=>setMYear(e.target.value)}>
                  <option value={false}> Academic Year</option>
                
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Term</label>
                   
                  <select className="form-select form-control" id="autoSizingSelect" onChange={(e)=>setTermO(e.target.value)}>
                  <option value={false}> Term</option>
                  { checkTerms() }
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>getStudent(e.target.value)} >
                    <option value={false}> Grade</option>
                    { 
                       gradeData === null ? '<option value="">No data available</option>' :
                       gradeData.map(gd=>(
                         <option value={gd.grade} key={gd.id}>{'Grade '+gd.grade}</option>
                       ))
                    }
                          
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Leaners</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>getSubject(e.target.value)}>
                    <option value={false}> Leaners</option>
                      { student && student.map(st=>(
                         <option key={st.id} value={st.student_id+'=>'+st.payment_status}>{ st.firstname+' '+st.lastname}</option>
                      ))}
                  </select>
                </div>


                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Subject</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> SubjectData(e.target.value)}>
                    <option value={false}> Subject</option>
                    { subject === null ? subjectStatus :
                       subject && subject.map(sub=>(
                          <option key={sub.id} value={sub.subject_id}>{ sub.subject}</option>
                       ))
                   }
                  </select>
                </div>

                <div className="col-sm-auto col-md-2">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Assessment</label>
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> viewCapture(e.target.value)}>
                    <option value={false}> Assessment</option>
                     {assessment === null ? assStatus : 
                          assessment && assessment.map(as=>(
                                <option key={as.id} value={as.invigilator_id+'-'+as.assessment}>{ as.download_type }</option>
                          ))
                     }
                  </select>
                </div>
                
                <center>
                  { payStatus === null ? null : payStatus }
                    { loadingstatus !== false ?
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : 
                                            vigilate !== null ? <div className="alert alert-info text-center"> { vigilate }</div> :
                                            null
}

                </center>
              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}
        </div>
        {/* end col */}
      </div>


      
        <div className="row">
        <div className="col-lg-12">
        { viewAssData=== null ? null : HTMLReactParser(viewAssData)}
      
              </div>
        </div>

        
        
          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <Footer />
      </div>
    
    )
}

export default AssessmentSummary
